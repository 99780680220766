<template>
  <div>
    <v-row dense>
      <v-col
        md="12"
        cols="12"
      >
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
                dense
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">EMPLOYEE'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="status"
                        label="Status"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="date_of_employment"
                        label="Date of Employment"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="employment_duration"
                        label="Employee Duration"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col md="5"
                       cols="12">
                  <v-card
                    flat
                    dense
                  >
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">COMPENSATION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-if="category_text!='BOD'"
                            v-model="compensation_rate"
                            label="Compensation Rate"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-if="category_text!='BOD'"
                            v-model="no_of_days"
                            label="No. of Days"
                            dense
                            outlined
                            type="number"
                            min="0"
                            max="31"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="holiday_pay"
                            label="Holiday Pay"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-if="holiday_pay>0"
                            v-model="holiday_pay_details"
                            label="Holiday Details"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="rice_allowance"
                            label="Food Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="birthday_allowances"
                            label="Birthday Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="trainee_allowances"
                            label="Trainee Allowance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="incentives_allowances"
                            label="Overtime"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            v-if="category_text==='BOD'"
                            v-model="bod_allowance"
                            label="BOD ALLOWANCE"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            background-color="info"
                            style="font-size: x-large"
                            v-model="gross_salary_formated"
                            label="Gross Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="7"
                       cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">SALARY DEDUCTION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-card flat dense>
                        <v-toolbar dense dark color="primary">
                          <v-toolbar-title><h5 class="font-weight-light">1st PRIORITY</h5>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="mt-3">
                          <v-row dense>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_sss"
                                label="SSS #"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_philhealth"
                                label="PhilHealth"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="status != 'Trainee'"
                                v-model="deduction_pagibig"
                                label="Pag-Ibig"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_coop_loan"
                                label="COOP Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_plan"
                                label="Damayan Plan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_late"
                                label="Late"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="no_of_absent"
                                label="No. of Absent"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="no_of_related_attendance"
                                label="No. of Days Related to Attendance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_tax"
                                label="TAX"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_cash_bond"
                                label="Cash Bond"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_sss_loan"
                                label="SSS Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_pagibig_loan"
                                label="Pag-Ibig Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_others"
                                label="Others"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-if="deduction_others > 0"
                                v-model="other_details"
                                label="Specify Others"
                                dense
                                outlined
                                :rules="rules.default_max_45_character_and_no_empty_rule"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_coop_save"
                                label="COOP Save"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card flat dense>
                        <v-toolbar dense dark color="primary">
                          <v-toolbar-title><h5 class="font-weight-light">2nd PRIORITY</h5>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="mt-3">
                          <v-row dense>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_loan"
                                label="Accounting Loans"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_auto_loan"
                                label="Auto Loan"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_ca"
                                label="Cash Advance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_motor"
                                label="Motor Finance"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                              <v-text-field
                                v-model="deduction_orec"
                                label="Other Receivable"
                                dense
                                outlined
                                type="number"
                                min="0"
                                @change="computation_of_salary"
                                @keyup="computation_of_salary"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-row dense>
                        <v-col md="12" cols="12">
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert
                    color="warning"
                    text
                    class="mb-0"

                  >
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{alert_message}}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    class="w-full"
                    color="primary"
                    @click="save_employee"
                    v-if="!saving"
                  >
                    Save changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPencilOutline,} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      full_name: '',
      branch: '',
      position: '',
      status: '',
      date_of_employment: '',
      employment_duration: '',
      compensation_rate: 0,
      no_of_days: 0,
      holiday_pay: 0,
      holiday_pay_details: '',
      bod_allowance: 0,
      rice_allowance: 0,
      birthday_allowances: 0,
      trainee_allowances: 0,
      incentives_allowances: 0,
      gross_salary: 0,
      gross_salary_formated: '0.00',
      deduction_sss: 0,
      deduction_loan: 0,
      deduction_auto_loan: 0,
      deduction_philhealth: 0,
      deduction_ca: 0,
      deduction_coop_loan: 0,
      deduction_pagibig: 0,
      deduction_plan: 0,
      deduction_coop_save: 0,
      deduction_late: 0,
      deduction_tax: 0,
      deduction_motor: 0,
      deduction_orec: 0,
      deduction_sss_loan: 0,
      deduction_cash_bond: 0,
      deduction_pagibig_loan: 0,
      no_of_absent: 0,
      no_of_related_attendance: 0,
      deduction_others: 0,
      other_details: '',
      net_salary: 0,
      related_attendance_amount: 0,
      absent_amount: 0,
      net_salary_formated: '0.00',

      particulars: '',
      category_text: '',

      bank_type: '',
      bank_account_name: '',
      bank_account_no: '',

      orig_deduction_auto_loan: 0,
      orig_deduction_ca: 0,
      orig_deduction_loan: 0,
      orig_deduction_coop_loan: 0,
      orig_deduction_orec: 0,
      orig_deduction_motor: 0,
      orig_deduction_plan: 0,
      orig_deduction_coop_save: 0,
      orig_deduction_sss: 0,
      orig_deduction_others: 0,
      orig_deduction_tax: 0,

      orig_deduction_pagibig: 0,
      orig_deduction_philhealth: 0,
      orig_deduction_cash_bond: 0,
      orig_deduction_pagibig_loan: 0,
      orig_deduction_sss_loan: 0,
      orig_deduction_late: 0,
      orig_absent_amou: 0,
      orig_related_attendance_amount: 0,
    }
  }
  export default {
    props: {
      value: Object,
      month_of_id: Number,
      duration: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    async mounted() {
      this.category_text = this.value.employee.category.category
      this.full_name = this.value.employee.last_name + ', ' + this.value.employee.first_name + ' ' + this.value.employee.middle_name
      this.branch = this.value.employee.branch.branch_code
      this.position = this.value.employee.employee_position.position
      this.status = this.value.employee.status
      this.date_of_employment = moment(this.value.employee.date_of_employment, 'YYYY-MM-DD').format('MMMM DD, YYYY')
      var a = moment();
      var b = moment(this.value.employee.date_of_employment, 'YYYY-MM-DD');

      var years = a.diff(b, 'year');
      b.add(years, 'years');

      var months = a.diff(b, 'months');
      b.add(months, 'months');

      var days = a.diff(b, 'days');
      this.employment_duration = years + ' years ' + months + ' months ' + days + ' days'
      this.compensation_rate = this.value.compensation_rate
      this.particulars = this.value.particulars
      this.bank_type = this.value.bank_type
      this.bank_account_name = this.value.bank_account_name
      this.bank_account_no = this.value.bank_account_no

      this.no_of_days = this.value.no_of_days
      this.holiday_pay = this.value.holiday_pay
      this.holiday_pay_details = this.value.holiday_pay_details
      this.bod_allowance = this.value.bod_allowance
      this.rice_allowance = this.value.rice_allowance
      this.birthday_allowances = this.value.birthday_allowances
      this.trainee_allowances = this.value.trainee_allowances
      this.incentives_allowances = this.value.incentives_allowances
      this.gross_salary = this.value.gross_salary

      this.absent_amount = this.value.absent_amount
      this.related_attendance_amount = this.value.related_attendance_amount
      this.deduction_sss = this.value.deduction_sss
      this.deduction_sss_loan = this.value.deduction_sss_loan
      this.deduction_cash_bond = this.value.deduction_cash_bond
      this.deduction_loan = this.value.deduction_loan
      this.deduction_auto_loan = this.value.deduction_auto_loan
      this.deduction_philhealth = this.value.deduction_philhealth
      this.deduction_ca = this.value.deduction_ca
      this.deduction_coop_loan = this.value.deduction_coop_loan
      this.deduction_pagibig = this.value.deduction_pagibig
      this.deduction_pagibig_loan = this.value.deduction_pagibig_loan
      this.deduction_plan = this.value.deduction_plan
      this.deduction_coop_save = this.value.deduction_coop_save
      this.deduction_late = this.value.deduction_late
      this.deduction_tax = this.value.deduction_tax
      this.deduction_motor = this.value.deduction_motor
      this.no_of_absent = this.value.no_of_absent
      this.no_of_related_attendance = this.value.no_of_related_attendance
      this.deduction_orec = this.value.deduction_orec
      this.deduction_others = this.value.deduction_others
      this.deduction_cash_bond = this.value.deduction_cash_bond
      this.other_details = this.value.other_details
      this.net_salary = this.value.net_salary


      this.gross_salary_formated = this.formatPrice(this.gross_salary)
      this.net_salary_formated = this.formatPrice(this.net_salary)

      const data = new FormData()
      data.append('month_of_id', this.month_of_id)
      data.append('duration', this.duration)
      data.append('employee_id', this.value.employee.id)
      await this.employee_for_salaries_data(data)
        .then(response => {

          var sss_d = 0
          var au_loan = 0
          var ca_de = 0
          var act_l = 0
          var o_recs = 0
          var moto_f = 0
          var plan_f = 0
          var coop_saveeee = 0
          if (response.data.salary_deductions.length > 0) {
            response.data.salary_deductions.forEach(function (item) {
              switch (item.type) {
                case 'SSS':
                  sss_d = parseFloat(sss_d) + parseFloat(item.amount)
                  break
                case 'Auto Loan':
                  au_loan = parseFloat(au_loan) + parseFloat(item.amount)
                  break
                case 'Cash Advance':
                  ca_de = parseFloat(ca_de) + parseFloat(item.amount)
                  break
                case 'Accounting Loan':
                  act_l = parseFloat(act_l) + parseFloat(item.amount)
                  break
                case 'Other Receivable':
                  o_recs = parseFloat(o_recs) + parseFloat(item.amount)
                  break
                case 'Motor Finance':
                  moto_f = parseFloat(moto_f) + parseFloat(item.amount)
                  break
                case 'Plan':
                  plan_f = parseFloat(plan_f) + parseFloat(item.amount)
                  break
                case 'Coop Save':
                  coop_saveeee = parseFloat(coop_saveeee) + parseFloat(item.amount)
                  break
              }
            })
          }
          this.orig_deduction_auto_loan = au_loan
          this.orig_deduction_ca = ca_de
          this.orig_deduction_loan = act_l
          this.orig_deduction_orec = o_recs
          this.orig_deduction_motor = moto_f
          this.orig_deduction_plan = plan_f
          this.orig_deduction_coop_save = coop_saveeee
          this.orig_deduction_sss = sss_d
          this.orig_deduction_others = this.value.deduction_others
          this.orig_deduction_tax = this.value.deduction_tax
          this.orig_deduction_pagibig = this.value.deduction_pagibig
          this.orig_deduction_philhealth = this.value.deduction_philhealth
          this.orig_deduction_cash_bond = this.value.deduction_cash_bond
          this.orig_deduction_pagibig_loan = this.value.deduction_pagibig_loan
          this.orig_deduction_sss_loan = this.value.deduction_sss_loan
          this.orig_deduction_late = this.value.deduction_late
          this.orig_absent_amou = this.value.absent_amount
          this.orig_related_attendance_amount = this.value.related_attendance_amount
        })
      this.computation_of_salary()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['update_salary', 'employee_for_salaries_data']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.particulars = ''
        this.full_name = ''
        this.branch = ''
        this.position = ''
        this.status = ''
        this.date_of_employment = ''
        this.employment_duration = ''
        this.bank_type = ''
        this.bank_account_name = ''
        this.bank_account_no = ''
        this.compensation_rate = 0
        this.no_of_days = 0
        this.holiday_pay = 0
        this.holiday_pay_details = ''
        this.bod_allowance = 0
        this.rice_allowance = 0
        this.birthday_allowances = 0
        this.trainee_allowances = 0
        this.incentives_allowances = 0
        this.gross_salary = 0
        this.gross_salary_formated = '0.00'
        this.deduction_sss = 0
        this.deduction_loan = 0
        this.deduction_auto_loan = 0
        this.deduction_philhealth = 0
        this.deduction_ca = 0
        this.deduction_coop_loan = 0
        this.deduction_pagibig = 0
        this.deduction_plan = 0
        this.deduction_coop_save = 0
        this.deduction_late = 0
        this.deduction_tax = 0
        this.deduction_motor = 0
        this.deduction_orec = 0
        this.deduction_pagibig_loan = 0
        this.deduction_sss_loan = 0
        this.deduction_cash_bond = 0
        this.no_of_absent = 0
        this.no_of_related_attendance = 0
        this.deduction_others = 0
        this.other_details = ''
        this.net_salary = 0
        this.net_salary_formated = '0.00'
        this.selectedDepositId = -1
        this.orig_deduction_auto_loan = 0
        this.orig_deduction_ca = 0
        this.orig_deduction_loan = 0
        this.orig_deduction_orec = 0
        this.orig_deduction_motor = 0
        this.orig_deduction_plan = 0
        this.orig_deduction_coop_save = 0
        this.orig_deduction_sss = 0
        this.orig_deduction_others = 0
        this.orig_deduction_tax = 0
        this.orig_deduction_pagibig = 0
        this.orig_deduction_philhealth = 0
        this.orig_deduction_cash_bond = 0
        this.orig_deduction_pagibig_loan = 0
        this.orig_deduction_sss_loan = 0
        this.orig_deduction_late = 0
        this.orig_absent_amou = 0
        this.orig_related_attendance_amount = 0
      },
      computation_of_salary() {
        this.check_if_input_number_correct()

        this.gross_salary =
          parseFloat(this.no_of_days) * parseFloat(this.compensation_rate) +
          parseFloat(this.bod_allowance) +
          parseFloat(this.holiday_pay) +
          parseFloat(this.rice_allowance) +
          parseFloat(this.birthday_allowances) +
          parseFloat(this.trainee_allowances) +
          parseFloat(this.incentives_allowances)

        this.absent_amount = parseFloat(this.no_of_absent) * parseFloat(this.compensation_rate)
        this.related_attendance_amount = parseFloat(this.no_of_related_attendance) * parseFloat(this.compensation_rate)
        var deductions =
          parseFloat(this.deduction_sss) +
          parseFloat(this.deduction_pagibig) +
          parseFloat(this.deduction_philhealth) +
          parseFloat(this.deduction_pagibig_loan) +
          parseFloat(this.deduction_sss_loan) +
          parseFloat(this.deduction_cash_bond) +
          parseFloat(this.deduction_coop_loan) +
          parseFloat(this.deduction_tax) +
          parseFloat(this.deduction_plan) +
          parseFloat(this.deduction_late) +
          parseFloat(this.absent_amount) +
          parseFloat(this.related_attendance_amount)+
          parseFloat(this.deduction_coop_save) +
          parseFloat(this.deduction_others)

        var fourty = parseFloat(this.gross_salary) * 0.4;
        var sixty = parseFloat(this.gross_salary) * 0.6;
        var should_net = (parseFloat(this.gross_salary) - parseFloat(deductions));


        var remaining = should_net - fourty;
        this.deduction_loan = ((parseFloat(this.orig_deduction_loan) > 0 ? parseFloat(this.orig_deduction_loan) : (parseFloat(this.deduction_loan))));
        this.deduction_auto_loan = ((parseFloat(this.orig_deduction_auto_loan) > 0 ? parseFloat(this.orig_deduction_auto_loan) : (parseFloat(this.deduction_auto_loan))));
        this.deduction_ca = ((parseFloat(this.orig_deduction_ca) > 0 ? parseFloat(this.orig_deduction_ca) : (parseFloat(this.deduction_ca))));
        this.deduction_motor = ((parseFloat(this.orig_deduction_motor) > 0 ? parseFloat(this.orig_deduction_motor) : (parseFloat(this.deduction_motor))));
        this.deduction_orec = ((parseFloat(this.orig_deduction_orec) > 0 ? parseFloat(this.orig_deduction_orec) : (parseFloat(this.deduction_orec))));


        var counter = 0
        counter += parseFloat(this.deduction_loan) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_auto_loan) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_ca) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_motor) > 0 ? 1 : 0;
        counter += parseFloat(this.deduction_orec) > 0 ? 1 : 0;
        var ded_eq = Math.floor(remaining / counter);
        if (remaining > 0) {
          if (ded_eq > 0) {
            this.deduction_loan = (parseFloat(this.orig_deduction_loan) > ded_eq || parseFloat(this.deduction_loan) > ded_eq ? ded_eq : this.deduction_loan);
            this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > ded_eq || parseFloat(this.deduction_auto_loan) > ded_eq ? ded_eq : this.deduction_auto_loan);
            this.deduction_ca = (parseFloat(this.orig_deduction_ca) > ded_eq || parseFloat(this.deduction_ca) > ded_eq ? ded_eq : this.deduction_ca);
            this.deduction_motor = (parseFloat(this.orig_deduction_motor) > ded_eq || parseFloat(this.deduction_motor) > ded_eq ? ded_eq : this.deduction_motor);
            this.deduction_orec = (parseFloat(this.orig_deduction_orec) > ded_eq || parseFloat(this.deduction_orec) > ded_eq ? ded_eq : this.deduction_orec);

            var second = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
              parseFloat(this.deduction_ca) +
              parseFloat(this.deduction_motor) +
              parseFloat(this.deduction_orec)
            var remaining2 = (remaining - second);
            if (remaining2 > 0) {
              var ded_eq2 = Math.floor(remaining2 / counter);
              if (ded_eq2 > 0) {
                this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_loan);
                this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_auto_loan);
                this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_ca);
                this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_motor);
                this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2) ? (ded_eq + ded_eq2) : this.deduction_orec);

                var third = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                  parseFloat(this.deduction_ca) +
                  parseFloat(this.deduction_motor) +
                  parseFloat(this.deduction_orec)
                var remaining3 = (remaining - third);
                if (remaining3 > 0) {
                  var ded_eq3 = Math.floor(remaining3 / counter);
                  if (ded_eq3 > 0) {
                    this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_loan);
                    this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_auto_loan);
                    this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_ca);
                    this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_motor);
                    this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3) ? (ded_eq + ded_eq2 + ded_eq3) : this.deduction_orec);

                    var four = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                      parseFloat(this.deduction_ca) +
                      parseFloat(this.deduction_motor) +
                      parseFloat(this.deduction_orec)
                    var remaining4 = (remaining - four);
                    if (remaining4 > 0) {
                      var ded_eq4 = Math.floor(remaining4 / counter);
                      if (ded_eq4 > 0) {
                        this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_loan);
                        this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_auto_loan);
                        this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_ca);
                        this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_motor);
                        this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4) : this.deduction_orec);


                        var five = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
                          parseFloat(this.deduction_ca) +
                          parseFloat(this.deduction_motor) +
                          parseFloat(this.deduction_orec)
                        var remaining5 = (remaining - five);
                        if (remaining5 > 0) {
                          var ded_eq5 = Math.floor(remaining5 / counter);
                          if (ded_eq5 > 0) {
                            this.deduction_loan = (parseFloat(this.orig_deduction_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_loan);
                            this.deduction_auto_loan = (parseFloat(this.orig_deduction_auto_loan) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_auto_loan);
                            this.deduction_ca = (parseFloat(this.orig_deduction_ca) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_ca);
                            this.deduction_motor = (parseFloat(this.orig_deduction_motor) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_motor);
                            this.deduction_orec = (parseFloat(this.orig_deduction_orec) > (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) ? (ded_eq + ded_eq2 + ded_eq3 + ded_eq4 + ded_eq5) : this.deduction_orec);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            this.deduction_loan = 0
            this.deduction_auto_loan = 0
            this.deduction_ca = 0
            this.deduction_motor = 0
            this.deduction_orec = 0
          }
        } else {
          this.deduction_loan = 0
          this.deduction_auto_loan = 0
          this.deduction_ca = 0
          this.deduction_motor = 0
          this.deduction_orec = 0
        }
        var deductions2 = parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) +
          parseFloat(this.deduction_ca) +
          parseFloat(this.deduction_motor) +
          parseFloat(this.deduction_orec)
        this.net_salary = parseFloat(this.gross_salary) - (parseFloat(deductions) + parseFloat(deductions2))

        this.gross_salary_formated = this.formatPrice(this.gross_salary)
        this.net_salary_formated = this.formatPrice(this.net_salary)
      },
      check_if_input_number_correct() {
        if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
          this.no_of_days = 0
        }
        if (this.holiday_pay < 0 || this.holiday_pay === '') {
          this.holiday_pay = 0
        }
        if (this.rice_allowance < 0 || this.rice_allowance === '') {
          this.rice_allowance = 0
        }
        if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
          this.birthday_allowances = 0
        }
        if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
          this.trainee_allowances = 0
        }
        if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
          this.incentives_allowances = 0
        }
        if (this.bod_allowance < 0 || this.bod_allowance === '') {
          this.bod_allowance = 0
        }
        if (this.deduction_sss < 0 || this.deduction_sss === '') {
          this.deduction_sss = 0
        }
        if (this.deduction_loan < 0 || this.deduction_loan === '') {
          this.deduction_loan = 0
        }
        if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
          this.deduction_auto_loan = 0
        }
        if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
          this.deduction_philhealth = 0
        }
        if (this.deduction_ca < 0 || this.deduction_ca === '') {
          this.deduction_ca = 0
        }
        if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
          this.deduction_coop_loan = 0
        }
        if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
          this.deduction_pagibig = 0
        }
        if (this.deduction_plan < 0 || this.deduction_plan === '') {
          this.deduction_plan = 0
        }
        if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
          this.deduction_coop_save = 0
        }
        if (this.deduction_late < 0 || this.deduction_late === '') {
          this.deduction_late = 0
        }
        if (this.no_of_absent < 0 || this.no_of_absent === '') {
          this.no_of_absent = 0
        }
        if (this.no_of_related_attendance < 0 || this.no_of_related_attendance === '') {
          this.no_of_related_attendance = 0
        }
        if (this.deduction_tax < 0 || this.deduction_tax === '') {
          this.deduction_tax = 0
        }
        if (this.deduction_motor < 0 || this.deduction_motor === '') {
          this.deduction_motor = 0
        }
        if (this.deduction_others < 0 || this.deduction_others === '') {
          this.deduction_others = 0
        }
        if (this.deduction_orec < 0 || this.deduction_orec === '') {
          this.deduction_orec = 0
        }
        if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
          this.deduction_sss_loan = 0
        }
        if (this.deduction_cash_bond < 0 || this.deduction_cash_bond === '') {
          this.deduction_cash_bond = 0
        }
        if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
          this.deduction_pagibig_loan = 0
        }
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.value.id);

          data.append('duration', this.duration);
          data.append('batch_no', this.batch_no);
          data.append('branch', this.branch);
          data.append('position', this.position);
          data.append('status', this.status);
          data.append('employment_duration', this.employment_duration);
          data.append('bank_type', this.bank_type);
          data.append('bank_account_name', this.bank_account_name);
          data.append('bank_account_no', this.bank_account_no);

          data.append('no_of_days', this.no_of_days);
          data.append('compensation_rate', this.compensation_rate);
          data.append('gross_salary', this.gross_salary);

          data.append('bod_allowance', this.bod_allowance);
          data.append('rice_allowance', this.rice_allowance);
          data.append('trainee_allowances', this.trainee_allowances);
          data.append('birthday_allowances', this.birthday_allowances);
          data.append('transpo_allowances', 0);
          data.append('accomodation_allowances', 0);
          data.append('incentives_allowances', this.incentives_allowances);
          data.append('holiday_pay', this.holiday_pay);
          data.append('holiday_pay_details', this.holiday_pay_details);

          data.append('no_of_absent', this.no_of_absent);
          data.append('absent_amount', this.absent_amount);
          data.append('no_of_related_attendance', this.no_of_related_attendance);
          data.append('related_attendance_amount', this.related_attendance_amount);

          data.append('deduction_sss', this.deduction_sss);
          data.append('deduction_philhealth', this.deduction_philhealth);
          data.append('deduction_pagibig', this.deduction_pagibig);
          data.append('deduction_loan', this.deduction_loan);
          data.append('deduction_auto_loan', this.deduction_auto_loan);
          data.append('deduction_coop_loan', this.deduction_coop_loan);
          data.append('deduction_late', this.deduction_late);
          data.append('deduction_coop_save', this.deduction_coop_save);
          data.append('deduction_ca', this.deduction_ca);
          data.append('deduction_plan', this.deduction_plan);
          data.append('deduction_others', this.deduction_others);
          data.append('other_details', this.other_details);
          data.append('deduction_tax', this.deduction_tax);
          data.append('deduction_motor', this.deduction_motor);
          data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
          data.append('deduction_sss_loan', this.deduction_sss_loan)
          data.append('deduction_orec', this.deduction_orec)
          data.append('deduction_cash_bond', this.deduction_cash_bond)

          data.append('net_salary', this.net_salary);

          data.append('prepared_by', this.name);


          this.update_salary(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.$emit('data', true)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
